import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Layout from "./../../components/layout"
import SEO from "./../../components/seo"
import { OutboundLink } from "gatsby-plugin-google-gtag"

//import icons
import IconRightArrow from "./../../assets/icons/svg/arrow-point-to-right.svg"
import PingdomLogo from "./../../assets/icons/svg/pingdom-logo.svg"
import UptimeRobotLogo from "./../../assets/icons/svg/uptime_robot-logo.svg"
import StatusPageLogo from "./../../assets/icons/svg/statuspage-logo.svg"
import StatusCakeLogo from "./../../assets/icons/svg/statuscake-logo.svg"

//import css
import "./../../styles/alternatives-plateforms.css"

//import components
import GetStartedBanner from "./../../components/get-started-footer"
import PricingComparator from "./../../components/competitor-price-comparator"

const Header = ({ data }) => {
  return (
    <section className="alt-header-wrapper index-page">
      <div className="container">
        <div className="alt-header__title">
          <h1>Looking for an Website Monitoring alternative? Meet Odown.</h1>
        </div>
        <div className="alt-header__description">
          <p>
            If you’re looking for an alternative to Pingdom, UptimeRobot,
            StatusPage, StatusCake or PagerDuty then Odown is the perfect
            website monitoring service for you.
          </p>
          <p className="sub">
            Odown is a beautifully simple and reliable website monitoring.
            Status pages included.
          </p>
        </div>
        <div className="alt-header__button">
          <OutboundLink
            className="btn-primary"
            eventCategory="Launch the Odown App"
            eventAction="Click"
            eventLabel="Goto signup"
            href="https://app.odown.io/signup"
          >
            Start monitoring <IconRightArrow />
          </OutboundLink>
        </div>
      </div>
    </section>
  )
}

const AlternativesServices = ({ data }) => {
  const services = [
    {
      name: "UptimeRobot alternative",
      propose: "Website monitoring",
      description:
        "Switch from UptimeRobot to Odown and enjoy reliable service with no false positives and a users collaboration feature to share dashboards and alerts.",
      link: "/alternatives/uptimerobot",
      logo: <UptimeRobotLogo />,
    },
    {
      name: "Pingdom alternative",
      propose: "Website monitoring",
      description:
        "Switch from Pingdom to Odown and enjoy a simple service and an intuitive interface to easily monitor your uptime",
      link: "/alternatives/pingdom",
      logo: <PingdomLogo />,
    },
    {
      name: "StatusPage.io alternative",
      propose: "Website monitoring",
      description:
        "Switch from StatusPage.io to Odown and enjoy a simple service and an intuitive interface to easily monitor your uptime",
      link: "/alternatives/statuspage",
      logo: <StatusPageLogo />,
    },
    {
      name: "StatusCake alternative",
      propose: "Website monitoring",
      description:
        "Switch from StatusCake to Odown and enjoy a simple service and an intuitive interface to easily monitor your uptime",
      link: "/alternatives/statuscake",
      logo: <StatusCakeLogo />,
    },
    // {
    //     name : "PagerDuty alternative",
    // 	propose : "Website monitoring",
    //     description : "Switch from PagerDuty to Odown and enjoy a simple service and an intuitive interface to easily monitor your uptime" ,
    //     link : false,
    // 	logo : <PagerDutyLogo />

    // },
  ]

  return (
    <section className="alternatives-index-wrapper">
      <div className="container">
        <h2 className="title">Switch to Odown!</h2>
        <div className="content">
          <div className="service-cards">
            {services.map((item, index) => {
              return (
                <Link
                  key={index}
                  to={item.link !== false ? item.link : `#`}
                  className="service-card"
                >
                  <div className="service-card__flex">
                    <div className="service-icon"> {item.logo} </div>
                    <div className="service-details">
                      <div className="service-name">
                        <p>{item.name}</p>
                        <p className="service-propose">{item.propose}</p>
                      </div>
                      <div className="service-desc">
                        <p>{item.description}</p>
                      </div>
                    </div>
                  </div>
                </Link>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

/**
 *
 * @param {*}
 */
const Main = ({ data }) => (
  <Layout>
    <SEO
      title="Switch to Odown for Effective Website Monitoring and Public Status Pages"
      description="Tired of your current website monitoring tool or status page provider? Explore the powerful features and affordable pricing of Odown - a top alternative that delivers reliable all-in one uptime monitoring solution."
      pathname={`/alternatives`}
    />
    <Header />
    <AlternativesServices />
    <PricingComparator />

    {/* Get started banner */}
    <GetStartedBanner />
  </Layout>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
export default Main
